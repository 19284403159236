// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.redBorder__iB5Lp{border:1px solid red}.marginFour__t2VYu{margin:.25rem}`, "",{"version":3,"sources":["webpack://./src/pages/Example/Example.module.css"],"names":[],"mappings":"AAAA,kBACE,oBACF,CAEA,mBACE,aACF","sourcesContent":[".redBorder {\n  border: 1px solid red;\n}\n\n.marginFour {\n  margin: 0.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"redBorder": `redBorder__iB5Lp`,
	"marginFour": `marginFour__t2VYu`
};
export default ___CSS_LOADER_EXPORT___;
