/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "content/bff/api/v1/content_service.proto" (package "content.bff.api", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * Represents info content type, for example
 * Intro and Outro within a bundle
 *
 * @generated from protobuf message content.bff.api.Info
 */
export interface Info {
    /**
     * AIP name
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * Title of the info card
     *
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * Sub title of the info card
     *
     * @generated from protobuf field: string subtitle = 3;
     */
    subtitle: string;
    /**
     * Description of the info card
     *
     * @generated from protobuf field: string description = 4;
     */
    description: string;
    /**
     * Classification of the info card as an intro/outro card
     *
     * @generated from protobuf field: content.bff.api.InfoType type = 6;
     */
    type: InfoType;
    /**
     * Optional text on an intro card providing attribution
     *
     * @generated from protobuf field: string attribution = 7;
     */
    attribution: string;
    /**
     * Info image data and alt text
     *
     * @generated from protobuf field: content.bff.api.Image info_image = 8;
     */
    infoImage?: Image;
    /**
     * The bundle type that this Info is a part of
     *
     * @generated from protobuf field: content.bff.api.BundleType bundle_type = 9;
     */
    bundleType: BundleType;
}
/**
 * Request object to get info
 *
 * @generated from protobuf message content.bff.api.GetInfoRequest
 */
export interface GetInfoRequest {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * Used to retrieve a Task resource from the Action Service.
     *
     * @generated from protobuf field: string action_name = 2;
     */
    actionName: string;
}
/**
 * Image object containing base64 encoded bytes and alt text
 *
 * @generated from protobuf message content.bff.api.Image
 */
export interface Image {
    /**
     * @generated from protobuf field: bytes data = 1;
     */
    data: Uint8Array;
    /**
     * @generated from protobuf field: string alt_text = 2;
     */
    altText: string;
}
/**
 * InfoType represents intro and outro cards
 *
 * @generated from protobuf enum content.bff.api.InfoType
 */
export enum InfoType {
    /**
     * @generated from protobuf enum value: INFO_TYPE_UNSPECIFIED = 0;
     */
    INFO_TYPE_UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: INTRO = 1;
     */
    INTRO = 1,
    /**
     * @generated from protobuf enum value: OUTRO = 2;
     */
    OUTRO = 2
}
/**
 * @generated from protobuf enum content.bff.api.BundleType
 */
export enum BundleType {
    /**
     * @generated from protobuf enum value: BUNDLE_TYPE_UNSPECIFIED = 0;
     */
    BUNDLE_TYPE_UNSPECIFIED = 0,
    /**
     * Survey for pre-signup (non-registered) users as part of their
     * recruitment to VerilyMe studies
     *
     * @generated from protobuf enum value: PRE_QUALIFICATION_SURVEY = 1;
     */
    PRE_QUALIFICATION_SURVEY = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class Info$Type extends MessageType<Info> {
    constructor() {
        super("content.bff.api.Info", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "subtitle", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "type", kind: "enum", T: () => ["content.bff.api.InfoType", InfoType] },
            { no: 7, name: "attribution", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "info_image", kind: "message", T: () => Image },
            { no: 9, name: "bundle_type", kind: "enum", T: () => ["content.bff.api.BundleType", BundleType] }
        ], { "google.api.resource": { type: "content.verily.health/Info", pattern: ["infos/{infos}/versions/{version}"], plural: "infos", singular: "info" } });
    }
    create(value?: PartialMessage<Info>): Info {
        const message = { name: "", title: "", subtitle: "", description: "", type: 0, attribution: "", bundleType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Info>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Info): Info {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* string subtitle */ 3:
                    message.subtitle = reader.string();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* content.bff.api.InfoType type */ 6:
                    message.type = reader.int32();
                    break;
                case /* string attribution */ 7:
                    message.attribution = reader.string();
                    break;
                case /* content.bff.api.Image info_image */ 8:
                    message.infoImage = Image.internalBinaryRead(reader, reader.uint32(), options, message.infoImage);
                    break;
                case /* content.bff.api.BundleType bundle_type */ 9:
                    message.bundleType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Info, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* string subtitle = 3; */
        if (message.subtitle !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.subtitle);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* content.bff.api.InfoType type = 6; */
        if (message.type !== 0)
            writer.tag(6, WireType.Varint).int32(message.type);
        /* string attribution = 7; */
        if (message.attribution !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.attribution);
        /* content.bff.api.Image info_image = 8; */
        if (message.infoImage)
            Image.internalBinaryWrite(message.infoImage, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* content.bff.api.BundleType bundle_type = 9; */
        if (message.bundleType !== 0)
            writer.tag(9, WireType.Varint).int32(message.bundleType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message content.bff.api.Info
 */
export const Info = new Info$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInfoRequest$Type extends MessageType<GetInfoRequest> {
    constructor() {
        super("content.bff.api.GetInfoRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"], "google.api.resource_reference": { type: "content.verily.health/Info" } } },
            { no: 2, name: "action_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetInfoRequest>): GetInfoRequest {
        const message = { name: "", actionName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInfoRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInfoRequest): GetInfoRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string action_name */ 2:
                    message.actionName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInfoRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string action_name = 2; */
        if (message.actionName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.actionName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message content.bff.api.GetInfoRequest
 */
export const GetInfoRequest = new GetInfoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Image$Type extends MessageType<Image> {
    constructor() {
        super("content.bff.api.Image", [
            { no: 1, name: "data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "alt_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Image>): Image {
        const message = { data: new Uint8Array(0), altText: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Image>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Image): Image {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes data */ 1:
                    message.data = reader.bytes();
                    break;
                case /* string alt_text */ 2:
                    message.altText = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Image, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes data = 1; */
        if (message.data.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.data);
        /* string alt_text = 2; */
        if (message.altText !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.altText);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message content.bff.api.Image
 */
export const Image = new Image$Type();
/**
 * @generated ServiceType for protobuf service content.bff.api.ContentService
 */
export const ContentService = new ServiceType("content.bff.api.ContentService", [
    { name: "GetInfo", options: { "google.api.method_signature": ["name"], "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"], acceptPsat: true } }, I: GetInfoRequest, O: Info }
]);
