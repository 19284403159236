import {CssBaseline, ThemeProvider} from '@mui/material';
import {ConsumerLightTheme} from '@verily-src/react-design-system';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Info from '../../info/pages/info/Info';
import Example from '../../pages/Example/Example';

type RootProps = {
  name: string;
};

export default function Root(props: RootProps) {
  return (
    <ThemeProvider theme={ConsumerLightTheme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/me/bundle/info" element={<Info />}></Route>
          <Route path="/" element={<Example {...props} />}></Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
