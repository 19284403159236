import {useMediaQuery} from '@mui/material';

/**
 * Screen size for responsive design.
 *
 * - Large: 1024px to 1920px
 * - Medium: 768px to 1023px
 * - Small (mobile): 320px to 767px
 */
export type ScreenSize = 'large' | 'medium' | 'small';

/** Evaluates the current screen size. */
const useScreenSize = () => {
  /** Whether the screen size is >= 1024 px. */
  const isLarge = useMediaQuery('(min-width:1024px');

  /** Whether the screen size is >= 768px. */
  const isMedium = useMediaQuery('(min-width:768px');

  /** The current screen size. */
  const screenSize: ScreenSize = isLarge
    ? 'large'
    : isMedium
      ? 'medium'
      : 'small';

  return {screenSize};
};

export default useScreenSize;
