import {GrpcWebFetchTransport} from '@protobuf-ts/grpcweb-transport';
import {Auth} from '@verily-src/auth';
import {
  GetInfoRequest,
  Info,
} from '@verily-src/verily1-protos/content/bff/api/v1/content_service';
import {ContentServiceClient} from '@verily-src/verily1-protos/content/bff/api/v1/content_service.client';

export const getInfo = async (
  name: string,
  actionName: string
): Promise<Info> => {
  let host = '';
  if (process.env.NODE_ENV === 'development') {
    const TARGET_URL = 'localhost';
    // See the content BFF README for finding the correct port
    const TARGET_PORT = '3000';
    host = `http://${TARGET_URL}:${TARGET_PORT}`;
  }

  const interceptor = await Auth.getInterceptor();

  const transport = new GrpcWebFetchTransport({
    baseUrl: host + '/api/',
    interceptors: [interceptor],
  });

  const client = new ContentServiceClient(transport);
  const request: GetInfoRequest = {
    name: name,
    actionName: actionName,
  };
  try {
    return (await client.getInfo(request)).response;
  } catch (err) {
    return err;
  }
};
