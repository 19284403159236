export const headerStyles = {
  outerContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    background: 'background.canvas',
    borderBottom: '0.5px solid',
    borderBottomColor: 'background.separator',
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    maxWidth: '1136px',
    background: 'background.canvas',
  },
  innerContainerPadding: {
    large: {
      padding: '24px 32px 24px 32px',
    },
    medium: {
      padding: '24px 32px 24px 32px',
    },
    small: {
      padding: '16px 16px 16px 16px',
    },
  },
};

export const infoStyles = {
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    height: '100%',
    width: '100%',
    maxWidth: '748px',
    backgroundColor: 'background.canvas',
    overflow: 'auto',
  },
  outerContainerPadding: {
    large: {
      padding: '64px 32px 0px 32px',
    },
    medium: {
      padding: '24px 32px 0px 32px',
    },
    small: {
      padding: '16px 16px 0px 16px',
    },
  },
  titleVariant: {
    large: 'display6',
    medium: 'display3',
    small: 'display4',
  },
  attributionContainer: {
    marginTop: 'auto', // aligns attribution to bottom of info container
    paddingBottom: '24px',
  },
};

export const footerStyles = {
  outerContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    background: 'background.canvas',
    borderTop: '0.5px solid',
    borderTopColor: 'background.separator',
    marginTop: 'auto', // aligns footer container to bottom of screen
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    maxWidth: '748px',
    background: 'background.canvas',
  },
  innerContainerPadding: {
    large: {
      padding: '32px 32px 32px 32px',
    },
    medium: {
      padding: '32px 32px 32px 32px',
    },
    small: {
      padding: '24px 16px 24px 16px',
    },
  },
  nextButton: {
    width: '224px',
    marginLeft: 'auto',
  },
};
