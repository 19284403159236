// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infoPage__XroSp{flex-direction:column;height:100vh;width:100vw}.infoPage__XroSp,.spinnerOverlay__HH_rS{align-items:center;display:flex}.spinnerOverlay__HH_rS{background-color:rgba(0,0,0,.25);border-radius:1rem;bottom:0;justify-content:center;left:0;position:absolute;right:0;top:0}`, "",{"version":3,"sources":["webpack://./src/info/pages/info/info.css"],"names":[],"mappings":"AAAA,iBAKE,qBAAsB,CAJtB,YAAa,CACb,WAIF,CAEA,wCAJE,kBAAmB,CADnB,YAkBF,CAbA,uBAME,gCAAqC,CACrC,kBAAmB,CAJnB,QAAS,CAST,sBAAuB,CARvB,MAAO,CAHP,iBAAkB,CAIlB,OAAQ,CAHR,KAWF","sourcesContent":[".infoPage {\n  height: 100vh;\n  width: 100vw;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n}\n\n.spinnerOverlay {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  background-color: rgba(0, 0, 0, 0.25); /* half as opaque as dialog backdrop */\n  border-radius: 1rem; /* matches parent */\n\n  /* center the spinner in the overlay */\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoPage": `infoPage__XroSp`,
	"spinnerOverlay": `spinnerOverlay__HH_rS`
};
export default ___CSS_LOADER_EXPORT___;
