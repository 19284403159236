import verilyLogo from '../../assets/verilyLogo.svg';
import styles from './Example.module.css';

type ExampleProps = {
  name: string;
};

export default function Example(props: ExampleProps) {
  return (
    <div>
      <img src={verilyLogo} />
      <section className={`${styles.redBorder} ${styles.marginFour}`}>
        {props.name} is mounted!
      </section>
    </div>
  );
}
